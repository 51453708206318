'use strict';

var appConfig = window.appConfig || {};

appConfig.apiRootUrl = 'https://bezpecnost.praha.eu/Intens.CrisisPortalInfrastructureApp/';
appConfig.pdfApiRootUrl = 'https://bezpecnost.praha.eu/pdfCreator/';
appConfig.version = '395c549';
appConfig.googleApiKey = 'AIzaSyAO_FJ2SlqU8Q4STEHLGCilw_Y9_11qcW8';
appConfig.facebookAppId = '190469767638040';
appConfig.autoLogoutTime = 1000 * 60 * 60 * 24;
appConfig.galleryGridColumns = 6;
appConfig.videoGalleryGridColumns = 3;
appConfig.defaultCameraRefreshRate = 30000;
appConfig.cdnUrl = appConfig.apiRootUrl + 'cdn/files/';
appConfig.cdnImageUrl = appConfig.apiRootUrl + 'cdn/images/';
appConfig.uploadLimits = {
    images: {
        maxWidth: 2100,
        maxHeight: 2100,
        maxSize: '2.5MB'
    },
    documents: {
        maxSize: '50MB'
    }
};
appConfig.mapSettings = {
    externalDataSources: {
        covidDataUrl: 'https://apps.golemio.cz/v1/covid-odberova-mista-api/samplingpoints'
    },
    defaultMapCenter: {
        lat: 50.087429,
        lng: 14.421164,
        zoom: 9
    },
    /* ZC: tileLayer nize zakomentujme, zda se, ze je obsoletni */
    /*tileLayer: "https://gs-prv.praha.eu/arcgis/rest/services/dtmp/mtvu/MapServer/{z}/{x}/{y}.png",*/
    defaultLayers: {
        lineLayers: [{
            url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/2",
            tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
            key: "districts",
            color: "#000000",
            fillColor: "#000000",
            stroke: "#000000",
            visible: true,
            legendOptions: {
                label: "Hranice správních obvodů",
                colors: ["#000000"],
                visible: true,
                disable: true,
                display: true,
                order: 99999
            }
        }]
    },
    serverLayers: [{
        switcher: {
            key: "roads",
            title: "Orientační mapa",
            dependencies: ["nonveg", "veg", "relief"],
            activate: true
        },
        options: {
            url: "https://gs-prv.praha.eu/arcgis/rest/services/dtmp/mtvu/MapServer",
            tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
            key: "orientationmap",
            legendOptions: {
                label: "Orientační mapa",
                visible: true
            },
            visible: true
        }
    }, {
        switcher: {
            key: "relief",
            title: "Orientační mapa - reliéf",
            dependencies: ["roads", "nonveg", "veg"]
        },
        options: {
            url: "https://gs-prv.praha.eu/arcgis/rest/services/dtmp/mtvu_3d/MapServer",
            tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
            key: "reliefmap",
            enableTiling: true,
            legendOptions: {
                label: "Orientační reliéf",
                visible: true
            }
        }
    }, {
        switcher: {
            key: "veg",
            title: "Ortofoto mapa",
            dependencies: ["roads", "nonveg", "relief"]
        },
        options: {
            url: "https://gs-prv.praha.eu/imgs/rest/services/ort/letecke_snimkovani/ImageServer",
            tokenServiceUrl: "https://gs-prv.praha.eu/imgs",
            key: "airmap",
            enableTiling: true,
            legendOptions: {
                label: "Ortofoto mapa",
                visible: true
            }
        }
    }, {
        switcher: {
            key: "nonveg",
            title: "Ortofoto mapa - mimovegetační",
            dependencies: ["roads", "veg", "relief"]
        },
        options: {
            url: "https://gs-prv.praha.eu/imgs/rest/services/ort/mimovegetacni_letecke_snimkovani/ImageServer",
            tokenServiceUrl: "https://gs-prv.praha.eu/imgs",
            key: "airnonvegetationmap",
            enableTiling: true,
            legendOptions: {
                label: "Ortofoto nevegetační",
                visible: true
            }
        }
    }],
    newsLayers: {
        name: "Aktuální dění",
        trafficLayer: {
            key: "newstraffic",
            group: "traffic",
            url: "",
            visible: true,
            legendOptions: {
                label: "Mapa dopravy",
                subtitleMapWithImages: true,
                subtitleMap: {
                    "Informace": "dopravni_znacka_genericInfo.png",
                    "Námraza": "dopravni_znacka_namraza.png",
                    "Nebezpečí": "dopravni_znacka_nebezpeci.png",
                    "Dopravní nehoda": "dopravni_znacka_nehoda.png",
                    "Práce na silnici": "dopravni_znacka_prace.png",
                    "Vítr": "dopravni_znacka_vitr.png",
                    "Uzavírka": "dopravni_znacka_uzavirka.png"
                },
                visible: true,
                display: true,
                order: 1
            }
        },
        eventLayers: [
            {
                url: "",
                key: "publicnews",
                visible: true,
                legendOptions: {
                    label: "Události a plánované akce",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Událost": "udalost.png",
                        "Plánovaná akce": "planovana_udalost.png"
                    },
                    visible: true,
                    display: true,
                    order: 2
                }
            },
            {
                url: "",
                key: "privatenews",
                visible: true,
                legendOptions: {
                    label: "Neveřejné události a plánované akce",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Událost": "privatni_udalost.png",
                        "Plánovaná akce": "privatni_planovana_udalost.png"
                    },
                    visible: true,
                    display: true,
                    order: 3
                }
            }
        ],
        riverStationLayer: {
            url: "",
            visible: true,
            key: "riverStations",
            legendOptions: {
                label: "Vodočty",
                subtitleMapWithImages: true,
                subtitleMap: {
                    "Sucho": "dry.png",
                    "Normální stav": "normal.png",
                    "1. stupeň povodňové aktivity": "spa_1.png",
                    "2. stupeň povodňové aktivity": "spa_2.png",
                    "3. stupeň povodňové aktivity": "spa_3.png",
                    "3. stupeň povodňové aktivity - ohrožení": "spa_3_extreme_danger.png"
                },
                visible: true,
                display: true,
                order: 4
            }
        },
        airQualityLayer: {
            visible: true,
            url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
            tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
            key: "airQuality",
            legendOptions: {
                label: "Stav ovzduší",
                subtitleMapWithImages: true,
                subtitleMap: {
                    "Velmi dobrý až dobrý": "air-green.png",
                    "Přijatelný": "air-yellow.png",
                    "Zhoršený až špatný": "air-red.png",
                    "Neúplná data": "air-black.png"
                },
                visible: true,
                display: true,
                order: 5
            }
        }
    },
    trafficLayers: {
        name: "Doprava",
        trafficLayer: {
            key: "traffic",
            group: "traffic",
            url: "",
            visible: true,
            legendOptions: {
                label: "Mapa dopravy",
                subtitleMapWithImages: true,
                subtitleMap: {
                    "Informace": "dopravni_znacka_genericInfo.png",
                    "Námraza": "dopravni_znacka_namraza.png",
                    "Nebezpečí": "dopravni_znacka_nebezpeci.png",
                    "Dopravní nehoda": "dopravni_znacka_nehoda.png",
                    "Práce na silnici": "dopravni_znacka_prace.png",
                    "Vítr": "dopravni_znacka_vitr.png",
                    "Uzavírka": "dopravni_znacka_uzavirka.png"
                },
                visible: true,
                display: true,
                order: 6
            }
        },
        pointLayers: [
            {
                key: "parkingmachines",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/dop/zony_placeneho_stani/MapServer/1",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                dialogFn: "openDialogParkingMachine",
                iconUrl: "parkovaci-automaty.png",
                legendOptions: {
                    label: "Parkovací automaty",
                    images: ["parkovaci-automaty.png"],
                    visible: true,
                    display: true,
                    order: 7
                }
            }
        ],
        serverLayers: [
            {
                key: "borderszps",
                url: "https://gs-prv.praha.eu/arcgis/services/dop/psp_zony_parkovani/MapServer/WMSServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                wmsLayers: "0",
                isWms: true,
                legendOptions: {
                    label: "Hranice zóny placeného stání",
                    colors: ["#A900E6"],
                    images: ["zony-placeneho-stani.png"],
                    visible: true,
                    display: true,
                    order: 8
                }
            },
            {
                key: "winter",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/dop/zimni_udrzba/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                legendOptions: {
                    label: "Zimní údržba komunikací",
                    subtitleMap: {
                        "I. pořadí": "#00C900",
                        "II. pořadí": "#FA3411",
                        "III. pořadí": "#00A9E6",
                        "Neudržované": "#B2B2B2"
                    },
                    images: ["zimni-udrzba.png"],
                    visible: true,
                    display: true,
                    order: 9
                }
            },
            {
                key: "zoneszps",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/dop/zony_placeneho_stani/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:3",
                queryLayers: "3",
                opacity: 0.5,
                dialogFn: "openDialogZoneZps",
                legendOptions: {
                    label: "Vyznačení parkovacích stání",
                    subtitleMap: {
                        "Rezidentní úsek": "#73B2FF",
                        "Návštěvnický úsek": "#FFAA00",
                        "Smíšený úsek": "#CA7AF5",
                        "Úsek pouze se ZTP nebo se zákazem": "#CCCCCC"
                    },
                    visible: true,
                    display: true,
                    order: 10
                }
            },  
        ],
    },
    floodsLayers: {
        name: "Povodně",
        lineLayers: [
            {
                key: "floodcategory",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/16",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                legendOptions: {
                    label: "Kategorie záplavových území Vltavy a Berounky",
                    subtitleMap: {
                        "AZZU": "#B2B2B2",
                        "Území průtočná": "#FFFFBE",
                        "Území neprůtočná": "#E6A82C",
                        "Území určená k ochraně": "#FFD37F"
                    },
                    images: ["kategorie-zaplavovych-uzemi.png"],
                    visible: true,
                    display: true,
                    order: 13
                },
                propertyStyling: {
                    valueMap: {
                        "1": "#B2B2B2",
                        "2 VLT": "#FFFFBE",
                        "3": "#E6A82C",
                        "4": "#FFD37F",
                        "5": "#FFD37F",
                        "6": "#FFD37F",
                        "7": "#FFD37F"
                    },
                    featureProperty: "KAT_VLT",
                    styleProperties: ["color", "fillColor"]
                }
            },
            {
                key: "activezonevltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/15",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                //visible: true,
                color: "#B2B2B2",
                fillColor: "#B2B2B2",
                legendOptions: {
                    label: "Aktivní zóna na Vltavě a Berounce",
                    colors: ["#B2B2B2"],
                    images: ["aktivni-zona.png"],
                    visible: true,
                    display: true,
                    order: 14
                }
            },
            {
                key: "floodareaq5vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/4",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#A900E6",
                fillColor: "#A900E6",
                legendOptions: {
                    label: "Záplavové území pro průtok pětileté vody (Vltava, Berounka) s PPO, 1770 m<sup>3</sup>/s",
                    colors: ["#A900E6"],
                    images: ["zaplavove-uzemi-5-let.png"],
                    visible: true,
                    display: true,
                    order: 15
                }
            },
            {
                key: "floodareaq20vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/5",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#00E6A9",
                fillColor: "#00E6A9",
                legendOptions: {
                    label: "Záplavové území pro průtok dvacetileté vody (Vltava, Berounka) s PPO 2720 m<sup>3</sup>/s",
                    colors: ["#00E6A9"],
                    images: ["zaplavove-uzemi-20-let.png"],
                    visible: true,
                    display: true,
                    order: 16
                }
            },
            {
                key: "floodareaq50vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/6",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#FF00C5",
                fillColor: "#FF00C5",
                legendOptions: {
                    label: "Záplavové území pro průtok padesátileté vody (Vltava, Berounka) s PPO 3150 m<sup>3</sup>/s",
                    colors: ["#FF00C5"],
                    images: ["zaplavove-uzemi-50-let.png"],
                    visible: true,
                    display: true,
                    order: 17
                }
            },
            {
                key: "floodareaq100vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/7",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#0070C5",
                fillColor: "#0070C5",
                legendOptions: {
                    label: "Záplavové území pro průtok stoleté vody (Vltava, Berounka) s PPO 4020 m<sup>3</sup>/s",
                    colors: ["#0070C5"],
                    images: ["zaplavove-uzemi-100-let.png"],
                    visible: true,
                    display: true,
                    order: 18
                }
            },
            {
                key: "floodareaq2002vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/8",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#FF0000",
                fillColor: "#FF0000",
                legendOptions: {
                    label: "Záplavové území pro průtok v roce 2002 (Vltava, Berounka) s PPO, 5160 m<sup>3</sup>/s",
                    colors: ["#FF0000"],
                    images: ["zaplavove-uzemi-2002.png"],
                    visible: true,
                    display: true,
                    order: 19
                }
            },
            {
                key: "antifloodsandline",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/1",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#ffff00",
                fillColor: "#ffff00",
                visible: true,
                dialogFn: "openDialogFloodBarrier",
                legendOptions: {
                    label: "Linie protipovodňové ochrany - Pytle s pískem",
                    colors: ["#ffff00"],
                    images: ["pytle-s-piskem.png"],
                    visible: true,
                    display: true,
                    order: 20
                }
            },
            {
                key: "antifloodline",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/2",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#ff0000",
                fillColor: "#ff0000",
                visible: true,
                dialogFn: "openDialogFloodBarrier",
                legendOptions: {
                    label: "Linie protipovodňové ochrany",
                    subtitleMap: {
                        "Protipovodňová ochrana - pevné opatření": "#ff0051",
                        "Protipovodňová ochrana - mobilní stěna": "#4CE600"
                    },
                    images: ["protipovodnova-ochrana.png"],
                    visible: true,
                    display: true,
                    order: 21
                },
                propertyStyling: {
                    valueMap: {
                        "1": "#ff0051",
                        "2": "#4CE600"
                    },
                    featureProperty: "TYP",
                    styleProperties: ["color", "fillColor"]
                }
            }
        ],
        // riverStationLayer: {
        //     url: "",
        //     visible: true,
        //     key: "riverStations",
        //     legendOptions: {
        //         label: "Vodočty",
        //         subtitleMapWithImages: true,
        //         subtitleMap: {
        //             "Normální stav": "success.png",
        //             "1. stupeň povodňové aktivity": "notice.png",
        //             "2. stupeň povodňové aktivity": "warning.png",
        //             "3. stupeň povodňové aktivity": "danger.png",
        //             "Údaje nejsou k dispozici": "dry.png"
        //         },
        //         visible: true,
        //         display: true,
        //         order: 22
        //     }
        // }
    },
    shelterLayers: {
        name: "Ukrytí",
        lineLayers: [
            {
                key: "metrotunnels",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/11",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogMetroTunnels",
                color: "#009ACD",
                weight: 12,
                opacity: 0.5,
                fillOpacity: 0.5,
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "#458902",
                        "B": "#FFF400",
                        "C": "#FF073A"
                    },
                    styleProperties: ["color"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - tunely",
                    colors: ["#458902", "#FFF400", "#FF073A"],
                    images: ["ochrany-system-tunely.png"],
                    visible: true,
                    display: true,
                    order: 23
                }
            },
            {
                key: "strahovtunnel",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/7",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                dialogFn: "openDialogTrafficTunnel",
                visible: true,
                opacity: 0.5,
                fillOpacity: 0.5,
                legendOptions: {
                    label: "Dopravní tunel pod Strahovem",
                    colors: ["#6E87F5"],
                    images: ["dopravni-tunel-pod-strahovem.png"],
                    visible: true,
                    display: true,
                    order: 24
                }
            }
        ],
        pointLayers: [
            {
                key: "rotatingsirens",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "DRUH_SIRENY = 'rotační'",
                iconUrl: "sirenGreen.png",
                visible: true,
                disableClusterAtZoom: 8,
                dialogFn: "openDialogSiren",
                legendOptions: {
                    label: "Rotační sirény",
                    images: ["sirenGreen.png"],
                    visible: true,
                    display: true,
                    order: 25
                }
            },
            {
                key: "electronic",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "DRUH_SIRENY = 'elektronická'",
                iconUrl: "sirenRed.png",
                visible: true,
                disableClusterAtZoom: 8,
                dialogFn: "openDialogSiren",
                legendOptions: {
                    label: "Elektronické sirény",
                    images: ["sirenRed.png"],
                    visible: true,
                    display: true,
                    order: 26
                }
            },
            {
                key: "metrostations",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/10",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "TRASA IS NOT NULL",
                visible: true,
                dialogFn: "openDialogMetroStation",
                disableClusterAtZoom: 9,
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "greenMetro.png",
                        "B": "yellowMetro.png",
                        "C": "redMetro.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - stanice",
                    images: ["greenMetro.png", "yellowMetro.png", "redMetro.png"],
                    visible: true,
                    display: true,
                    order: 27
                }
            },
            {
                key: "metroentrances",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/8",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogMetroEntrance",
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "greenEnter.png",
                        "B": "yellowEnter.png",
                        "C": "redEnter.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - vstupy",
                    images: ["greenEnter.png", "yellowEnter.png", "redEnter.png"],
                    visible: true,
                    display: true,
                    order: 28
                }
            },
            {
                key: "metrofeatures",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/9",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                icon: new L.Icon({
                    iconUrl: "/styles/img/stick.png",
                    iconSize: new L.Point(2, 24),
                    iconAnchor: new L.Point(1, 12)
                }),
                legendOptions: {
                    label: "Hranice úseků ochran. syst. metra",
                    colors: ["#CCCCCC"],
                    images: ["hranice-useku.png"],
                    visible: true,
                    display: true,
                    order: 29
                },
                visible: true,
                disableClusterAtZoom: 9
            },
            {
                key: "shelterssmall",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/3",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogShelters",
                iconUrl: "shelterSmall.png",
                legendOptions: {
                    label: "Úkryty s kapacitou menší než 150 lidí",
                    images: ["shelterSmall.png"],
                    visible: true,
                    display: true,
                    order: 30
                }
            },
            {
                key: "sheltersbig",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/4",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogShelters",
                iconUrl: "shelterBig.png",
                legendOptions: {
                    label: "Úkryty s kapacitou 151 lidí a více",
                    images: ["shelterBig.png"],
                    visible: true,
                    display: true,
                    order: 31
                }
            }
        ]
    },
    chemistryLayers: {
        name: "Chemie",
        customLayers: [
            {
                url: "",
                key: "chemistry",
                visible: true,
                legendOptions: {
                    label: "Objekty s nebezpečnými látkami",
                    images: ["waste.png"],
                    visible: true,
                    display: true,
                    order: 32
                }
            }
        ]
    },
    technickaInfrastrukturaLayers: {
        name: "Technická infrastruktura",
        serverLayers: [
            {
                key: "vodovodni_rady",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/vodovody/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                visible: true,
                layers: "show:4",
                queryLayers: "4",
                dialogFn: "openDialogTiVodovodyRadModal",
                legendOptions: {
                    label: "Vodovodní řady",
                    //images: [".png"],
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Přiváděcí vodovodní řad": "ti_vodovody_privadeci_rad.png",
                        "Hlavní vodovodní řad": "ti_vodovody_hlavni_rad.png",
                        "Rozváděcí vodovodní řad": "ti_vodovody_rozvadeci_rad.png",
                        "Průmyslový vodovodní řad": "ti_vodovody_prumyslovy_rad.png"
                    },
                    visible: true,
                    display: true,
                    order: 501
                }
            },
            {
                key: "vodarenska_zarizeni_plochy",
                url: "http://gs-prv.praha.eu/arcgis/rest/services/ti/vodovody/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                visible: true,
                layers: "show:3",
                queryLayers: "3",
                legendOptions: {
                    label: "Vodárenská zařízení - plochy",
                    images: ["ti_vodovody_plocha.png"],
                    visible: true,
                    display: true,
                    order: 502
                }
            },
            {
                key: "kanalizce_stoky",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/kanalizace/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:4",
                queryLayers: "4",
                dialogFn: "openDialogTiKanalizaceStokaModal",
                legendOptions: {
                    label: "Kanalizační stoky",
                    //images: [".png"],
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Kmenová stoka": "ti_kanalizace_kmenova_stoka.png",
                        "Hlavní kanalizační sběrač": "ti_kanalizace_hlavni_sberac.png",
                        "Vedlejší kanalizační sběrač": "ti_vodovody_rozvadeci_rad.png",
                        "Vybraná stoka": "ti_kanalizace_vybrana_stoka.png",
                        "Ostatní stoky": "ti_kanalizace_ostatni_stoka.png"
                    },
                    visible: true,
                    display: true,
                    order: 504
                }
            },
            {
                key: "kanalizce_plochy",
                url: "http://gs-prv.praha.eu/arcgis/rest/services/ti/kanalizace/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:3",
                queryLayers: "3",
                legendOptions: {
                    label: "Kanalizační zařízení - plochy",
                    images: ["ti_kanalizace_plocha.png"],
                    visible: true,
                    display: true,
                    order: 505
                }
            },
            {
                key: "teplo_zarizeni",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/teplo/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:1",
                queryLayers: "2",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "TYP": "Typ",
                        "NAZEV": "Název"
                    }
                },
                legendOptions: {
                    label: "Tepelné zdroje",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Tepelný zdroj": "ti_teplo_zdroj.png",
                        "Tepelná zařízení - plochy": "ti_teplo_plocha.png",
                    },
                    visible: true,
                    display: true,
                    order: 506
                }
            },
            {
                key: "teplo_napajece",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/teplo/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:6",
                queryLayers: "6",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "KATEGORIE": "Kategorie",
                        "UMISTENI": "Umístění",
                        "TYP": "Typ",
                        "DIMENZE": "Dimenze"
                    }
                },
                legendOptions: {
                    label: "Tepelné napaječe",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Hlavní tepelný napaječ": "ti_teplo_hlavni_napajec.png",
                        "Hlavní tepelný napaječ v tunelu": "ti_teplo_hlavni_napajec_tunel.png",
                        "Tepelný napaječ, tepelný rozvod": "ti_teplo_napajec.png",
                    },
                    visible: true,
                    display: true,
                    order: 507
                }
            },
            {
                key: "plyn_zarizeni",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/plyn/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:1",
                queryLayers: "3",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "DRUH_ZARIZ": "Druh",
                        "NAZEV": "Název",
                        "TLAK_VSTUP": "Tlak na vstupu"
                    }
                },
                legendOptions: {
                    label: "Plynárenská zařízení",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Regulační stanice, VVTL": "ti_plyn_stanice_vvtl.png",
                        "Regulační stanice, VTL": "ti_plyn_stanice_vtl.png",
                        "Regulační stanice, STL": "ti_plyn_stanice_stl.png",
                        "Ostatní zařízení, VVTL; Plnírna plynu, VTL": "ti_plyn_stanice_ostatni.png",
                        "Plynárenská zařízení - plochy": "ti_plyn_plocha.png"
                    },
                    visible: true,
                    display: true,
                    order: 508
                }
            },
            {
                key: "plyn_plynovody",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/plyn/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:8",
                queryLayers: "8",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "TLAK": "Tlak",
                        "ULOŽENÍ": "Uložení",
                        "DIMENZE": "Dimenze"
                    }
                },
                legendOptions: {
                    label: "Plynovody",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "VVTL plynovod": "ti_plyn_plynovod_vvtl.png",
                        "VTL plynovod": "ti_plyn_plynovod_vtl.png",
                        "STL, NTL plynovod": "ti_plyn_plynovod_stl_ntl.png",
                    },
                    visible: true,
                    display: true,
                    order: 509
                }
            },
            {
                key: "elektrina_mve",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/elektrina/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:1",
                queryLayers: "2",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "TYP_ZARIZ": "Typ",
                        "NAZEV": "Název",
                        "NAPETI": "Napětí"
                    }
                },
                legendOptions: {
                    label: "Výrobny elektrické energie",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Malá vodní elektrárna": "ti_elektrina_mve.png",
                        "MVE - plochy": "ti_elektrina_mve_plocha.png"
                    },
                    visible: true,
                    display: true,
                    order: 510
                }
            },
            {
                key: "elektrina_stanice",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/elektrina/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:4",
                queryLayers: "4",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "TYP_ZARIZ": "Typ",
                        "NAZEV": "Název",
                        "ČÍSLO_TS": "Číslo TS",
                        "DRUH": "Druh",
                    }
                },
                legendOptions: {
                    label: "Elektrická stanice",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Transformovna 400 kV": "ti_elektrina_transformorovna_400.png",
                        "Transformovna 220 kV": "ti_elektrina_transformorovna_220.png",
                        "Transformovna 110 kV": "ti_elektrina_transformorovna_110.png",
                        "Transformovna 22 kV, 6 kV": "ti_elektrina_transformorovna_22_6.png",
                    },
                    visible: true,
                    display: true,
                    order: 511
                }
            },
            {
                key: "elektrina_vedeni",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/elektrina/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:8",
                /*
                 * popup pro elektricke vedeni zatim neresme, pokud ano, tak rozdelit vrstvu...
                queryLayers: "8",                
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "NAPETI": "Napětí",
                        "SUBTYPE": "Uložení"
                    }
                },
                */
                legendOptions: {
                    label: "Elektrické vedení",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Nadzemní trasa 400 kV": "ti_elektrina_trasa_400_nadzemni.png",
                        "Nadzemní trasa 220 kV": "ti_elektrina_trasa_220_nadzemni.png",
                        "Nadzemní trasa 110 kV": "ti_elektrina_trasa_110_nadzemni.png",
                        "Podzemní trasa 110 kV": "ti_elektrina_trasa_110_podzemni.png",
                        "Nadzemní trasa 22 kV": "ti_elektrina_trasa_22_nadzemni.png",
                        "Podzemní trasa 22 kV": "ti_elektrina_trasa_22_podzemni.png",
                        "Nadzemní trasa NN": "ti_elektrina_trasa_NN_nadzemni.png",
                        "Podzemní trasa NN": "ti_elektrina_trasa_NN_podzemni.png",
                        "Kabelový tunel, kabelový kanál": "ti_elektrina_kabelovy_tunel.png"
                    },
                    visible: true,
                    display: true,
                    order: 512
                }
            },
            {
                key: "odpady_skladky",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/zph/nakladani_s_odpady/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:0",
                queryLayers: "0",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "PROVOZOVNA": "Provozovna",
                    }
                },
                legendOptions: {
                    label: "Skládky",
                    images: ["ti_odpady_skladka.png"],
                    visible: true,
                    display: true,
                    order: 513
                }
            },
            {
                key: "odpady_spalovny",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/zph/nakladani_s_odpady/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:1",
                queryLayers: "1",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "PROVOZOVNA": "Provozovna",
                    }
                },
                legendOptions: {
                    label: "Spalovny",
                    images: ["ti_odpady_spalovna.png"],
                    visible: true,
                    display: true,
                    order: 514
                }
            },
            {
                key: "odpady_tridici_linky",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/zph/nakladani_s_odpady/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:2",
                queryLayers: "2",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "NAZEV": "Název",
                    }
                },
                legendOptions: {
                    label: "Třídící linky",
                    images: ["ti_odpady_tridici_linka.png"],
                    visible: true,
                    display: true,
                    order: 515
                }
            },
            {
                key: "odpady_nebezpecne_odpady",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/zph/nakladani_s_odpady/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:3",
                queryLayers: "3",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "PROVOZOVNA": "Provozovna",
                    }
                },
                legendOptions: {
                    label: "Zařízení nakládající s nebezpečnými odpady",
                    images: ["ti_nebezpecne_odpady.png"],
                    visible: true,
                    display: true,
                    order: 516
                }
            },
            {
                key: "odpady_sberne_dvory",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/zph/nakladani_s_odpady/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:4",
                queryLayers: "4",
                dialogFn: "openDialogZakladni",
                dialogFnOptions: {
                    dialogAttrs: {
                        "TYPOBJEKTU": "Typ",
                        "NAZEV": "Název",
                    }
                },
                legendOptions: {
                    label: "Sběrné dvory",
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Sběrný dvůr provozovaný MHMP": "ti_odpady_sberny_dvur_mhmp.png",
                        "Sběrný dvůr provozovaný MČ": "ti_odpady_sberny_dvur_mc.png",
                    },
                    visible: true,
                    display: true,
                    order: 517
                }
            },
        ],
        pointLayers: [
            {
                key: "vodarenska_zarizeni_points",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/vodovody/MapServer/2",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                dialogFn: "openDialogTiVodovodyZarizeniModal",
                visible: true,
                propertyStyling: {
                    featureProperty: "DRUH_ZARIZ",
                    valueMap: {
                        "1": "ti_vodovody_vodojem.png",
                        "2": "ti_vodovody_cerpaci_stanice.png",
                        "3": "ti_vodovody_cerpaci_stanice.png",
                        "4": "ti_vodovody_upravna_vody.png",
                        "5": "ti_vodovody_cerpaci_stanice.png",
                        "6": "ti_vodovody_cerpaci_stanice.png",
                        "7": "ti_vodovody_zdroj_vody.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Vodárenská zařízení",
                    //images: [".png"],
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Úpravna vody": "ti_vodovody_upravna_vody.png",
                        "Vodojem": "ti_vodovody_vodojem.png",
                        "Zdroj vody - studna": "ti_vodovody_zdroj_vody.png",
                        "Čerpací stanice; Redukční ventil; Vyrovnávací věž; Šoupátkový objekt": "ti_vodovody_cerpaci_stanice.png"
                    },
                    visible: true,
                    display: true,
                    order: 500
                },
                popupOptions: {
                    "property": "label",
                }
            },
            {
                key: "kanalizace_points",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/kanalizace/MapServer/2",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                dialogFn: "openDialogTiKanalizaceZarizeniModal",
                propertyStyling: {
                    featureProperty: "TYP",
                    valueMap: {
                        "1": "ti_kanalizace_cov.png",
                        "2": "ti_kanalizace_cerpaci_stanice.png",
                        "3": "ti_kanalizace_usazovaci_nadrz.png",
                        "5": "ti_kanalizace_vsakovaci_objekt.png",
                        "6": "ti_kanalizace_retencni_nadrz.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Kanalizační zařízení",
                    //images: [".png"],
                    subtitleMapWithImages: true,
                    subtitleMap: {
                        "Čistírna odpadních vod": "ti_kanalizace_cov.png", //1
                        "Dešťová usazovací nádrž": "ti_kanalizace_usazovaci_nadrz.png", //3
                        "Čerpací stanice": "ti_kanalizace_cerpaci_stanice.png", //2
                        "Retenční nádrž": "ti_kanalizace_retencni_nadrz.png", // 6
                        "Vsakovací objekt": "ti_kanalizace_vsakovaci_objekt.png" //5
                    },
                    visible: true,
                    display: true,
                    order: 503
                },
                popupOptions: {
                    "property": "label",
                }
            }
        ],
    },
    izsLayers: {
        name: "Bezpečnost",
        serverLayers: [
            {
                key: "addresses",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                isDynamic: true,
                layers: "show:7",
                queryLayers: "8",
                dialogFn: "openDialogAddresses",
                legendOptions: {
                    label: "Adresní body",
                    colors: ["#00A9E6"],
                    images: ["adresni-body.png"],
                    visible: true,
                    display: true,
                    order: 32
                }
            }
        ],
        pointLayers: [
            {
                key: "policecr",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                iconUrl: "police.png",
                visible: true,
                dialogFn: "openDialogPolice",
                legendOptions: {
                    label: "Objekty policie ČR",
                    images: ["police.png"],
                    visible: true,
                    display: true,
                    order: 33
                }
            },
            {
                key: "emergency",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/1",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                iconUrl: "emergency.png",
                visible: true,
                dialogFn: "openDialogEmergency",
                legendOptions: {
                    label: "Objekty Zdravotnické záchranné služby hl. m. Prahy",
                    images: ["emergency.png"],
                    visible: true,
                    display: true,
                    order: 34
                }
            },
            {
                key: "firebrigade",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/2",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                iconUrl: "fireman.png",
                visible: true,
                dialogFn: "openDialogFireBrigade",
                legendOptions: {
                    label: "Objekty požární ochrany",
                    images: ["fireman.png"],
                    visible: true,
                    display: true,
                    order: 35
                }
            },
            {
                key: "policemuni",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/3",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                iconUrl: "municipalPolice.png",
                visible: true,
                dialogFn: "openDialogMunicipalPolice",
                legendOptions: {
                    label: "Objekty Městské policie Praha",
                    images: ["municipalPolice.png"],
                    visible: true,
                    display: true,
                    order: 36
                }
            },
            {
                key: "volunterrfirebrigade",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/10",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                iconUrl: "volunteerfireman.png",
                visible: true,
                dialogFn: "openDialogVolunteerFireBrigade",
                legendOptions: {
                    label: "Hasičské zbrojnice (SDH)",
                    images: ["volunteerfireman.png"],
                    visible: true,
                    display: true,
                    order: 37
                }
            }
        ],
        lineLayers: [
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/4",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "munipoliceheadquarters",
                color: "#343434",
                fillColor: "#343434",
                stroke: "#343434",
                legendOptions: {
                    label: "Obvodní ředitelství Mětské policie Praha",
                    colors: ["#343434"],
                    images: ["obvodni-reditelstvi-mestske-policie.png"],
                    visible: true,
                    display: true,
                    order: 38
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/5",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "crpoliceheadquarters",
                color: "#285891",
                fillColor: "#285891",
                stroke: "#285891",
                legendOptions: {
                    label: "Obvodní ředitelství Policie ČR",
                    colors: ["#285891"],
                    images: ["obvodni-reditelstvi-policie.png"],
                    visible: true,
                    display: true,
                    order: 39
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/6",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "munipolicedistricts",
                color: "#000000",
                fillColor: "#000000",
                stroke: "#000000",
                legendOptions: {
                    label: "Okrsky Městské policie Praha",
                    colors: ["#000000"],
                    images: ["okrsky-mestske-policie.png"],
                    visible: true,
                    display: true,
                    order: 40
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/7",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "crpolicedistricts",
                color: "#285891",
                fillColor: "#285891",
                stroke: "#285891",
                legendOptions: {
                    label: "Okrsky policie ČR",
                    colors: ["#285891"],
                    images: ["okrsky-policie.png"],
                    visible: true,
                    display: true,
                    order: 41
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/integrovany_zachranny_system/MapServer/8",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "firedistricts",
                color: "#99312e",
                fillColor: "#99312e",
                stroke: "#99312e",
                legendOptions: {
                    label: "Hasební obvody",
                    colors: ["#99312e"],
                    images: ["hasebni-obvody.png"],
                    visible: true,
                    display: true,
                    order: 42
                }
            }
        ],
        publicLightingLayer: {
            url: "",
            key: "publiclighting",
            visible: true,
            legendOptions: {
                label: "Veřejné osvětlení",
                colors: ["#ffa500"],
                visible: true,
                display: true,
                order: 43
            }
        },
        btsMicrowaveStationLayer: {
            url: "",
            key: "btsmicrowavestation",
            legendOptions: {
                label: "Základnové stanice BTS",
                colors: ["#000000"],
                images: ["bts.png"],
                visible: true,
                display: true,
                order: 44
            }
        },
        asvvMicrowaveStationLayer: {
            url: "",
            key: "asvvmicrowavestation",
            color: "#e50000",
            fillColor: "#e50000",
            stroke: "#e50000",
            legendOptions: {
                label: "Mikrovlnné spoje ASVV",
                colors: ["#e50000"],
                visible: true,
                display: true,
                order: 45
            }
        },
        mrsMicrowaveStationLayer: {
            url: "",
            key: "mrsmicrowavestation",
            color: "#008000",
            fillColor: "#008000",
            stroke: "#008000",
            legendOptions: {
                label: "Mikrovlnné spoje MRS a Paging",
                colors: ["#008000", "#ff953f"],
                visible: true,
                display: true,
                order: 46
            }
        }
    },
    cadastreLayers: {
        name: "Územní členění",
        serverLayers: [
            {
                key: "cadastrez",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                isDynamic: true,
                layers: "show:12",
                queryLayers: "12",
                dialogFn: "openDialogCadastre",
                legendOptions: {
                    label: "Parcely",
                    colors: ["#000000"],
                    images: ["parcely.png"],
                    visible: true,
                    display: true,
                    order: 47
                }
            },
            {
                key: "addresses",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                isDynamic: true,
                layers: "show:7",
                queryLayers: "8",
                dialogFn: "openDialogAddresses",
                legendOptions: {
                    label: "Adresní body",
                    colors: ["#00A9E6"],
                    images: ["adresni-body.png"],
                    visible: true,
                    display: true,
                    order: 49
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "streets",
                isDynamic: true,
                layers: "show:4",
                legendOptions: {
                    label: "Uliční úseky",
                    colors: ["#FFFFFF"],
                    images: ["ulicni-useky.png"],
                    visible: true,
                    display: true,
                    order: 50
                }
            }
        ],
        lineLayers: [
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "prague",
                color: "#E60000",
                fillColor: "#E60000",
                stroke: "#E60000",
                legendOptions: {
                    label: "Hranice Prahy",
                    colors: ["#E60000"],
                    images: ["hranice-prahy.png"],
                    visible: true,
                    display: true,
                    order: 51
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/1",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "citydistricts",
                color: "#8C005E",
                fillColor: "#8C005E",
                stroke: "#8C005E",
                legendOptions: {
                    label: "Hranice městských částí",
                    images: ["hranice-mestskych-casti.png"],
                    colors: ["#8C005E"],
                    visible: true,
                    display: true,
                    order: 52
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/10",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "buildings",
                color: "#663838",
                fillColor: "#663838",
                stroke: "#663838",
                legendOptions: {
                    label: "Stavební objekty",
                    colors: ["#663838"],
                    images: ["stavebni-objekty.png"],
                    visible: true,
                    display: true,
                    order: 54
                }
            },
            {
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/3",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                key: "cadastredistricts",
                color: "#C08FDB",
                fillColor: "#C08FDB",
                stroke: "#C08FDB",
                legendOptions: {
                    label: "Hranice katastrální území",
                    colors: ["#C08FDB"],
                    images: ["hranice-katastralni-uzemi.png"],
                    visible: true,
                    display: true,
                    order: 55
                }
            }
        ]
    },
    cameraLayers: {
        name: "Kamery",
        customLayers: [
            {
                url: "",
                key: "publiccameras",
                visible: true,
                legendOptions: {
                    label: "Veřejné kamery",
                    images: ["camera_public.png"],
                    visible: true,
                    display: true,
                    order: 56
                }
            },
            {
                url: "",
                key: "privatecameras",
                visible: true,
                legendOptions: {
                    label: "Neveřejné kamery",
                    images: ["camera_private.png"],
                    visible: true,
                    display: true,
                    order: 57
                }
            },
            {
                url: "",
                key: "kamerovebody",
                //visible: true,
                legendOptions: {
                    label: "Kamerové body",
                    images: ["camera_point.png"],
                    visible: true,
                    display: true,
                    order: 58
                }
            }
        ]
    },
    /*
    covidLayers: {
        name: "COVID-19",
        customLayers: [
            {
                url: "",
                key: "covid",
                visible: true,
                legendOptions: {
                    label: "Odběrová místa na COVID-19",
                    images: ["covid.png"],
                    visible: true,
                    display: true,
                    order: 58
                }
            }
        ]
    },
    */
    stanoviskaLayers: {
        name: "Stanoviska",        
        lineLayers: [
            {
                key: "metrotunnels",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/11",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogMetroTunnels",
                color: "#009ACD",
                weight: 12,
                opacity: 0.5,
                fillOpacity: 0.5,
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "#458902",
                        "B": "#FFF400",
                        "C": "#FF073A"
                    },
                    styleProperties: ["color"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - tunely",
                    colors: ["#458902", "#FFF400", "#FF073A"],
                    images: ["ochrany-system-tunely.png"],
                    visible: true,
                    display: true,
                    order: 1100
                }
            },
            {
                key: "strahovtunnel",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/7",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                dialogFn: "openDialogTrafficTunnel",
                visible: true,
                opacity: 0.5,
                fillOpacity: 0.5,
                legendOptions: {
                    label: "Dopravní tunel pod Strahovem",
                    colors: ["#6E87F5"],
                    images: ["dopravni-tunel-pod-strahovem.png"],
                    visible: true,
                    display: true,
                    order: 1101
                }
            }            
        ],
        floodLineLayers: [
            {
                key: "floodareaq2002vltava",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/8",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#FF0000",
                fillColor: "#FF0000",
                legendOptions: {
                    label: "Záplavové území pro průtok v roce 2002 (Vltava, Berounka) s PPO, 5160 m<sup>3</sup>/s",
                    colors: ["#FF0000"],
                    images: ["zaplavove-uzemi-2002.png"],
                    visible: true,
                    display: true,
                    order: 1112
                }
            },
            {
                key: "antifloodsandline",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/1",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#ffff00",
                fillColor: "#ffff00",
                //visible: true,
                dialogFn: "openDialogFloodBarrier",
                legendOptions: {
                    label: "Linie protipovodňové ochrany - Pytle s pískem",
                    colors: ["#ffff00"],
                    images: ["pytle-s-piskem.png"],
                    visible: true,
                    display: true,
                    order: 1113
                }
            },
            {
                key: "antifloodline",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/ti/zaplavy/MapServer/2",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                color: "#ff0000",
                fillColor: "#ff0000",
                //visible: true,
                dialogFn: "openDialogFloodBarrier",
                legendOptions: {
                    label: "Linie protipovodňové ochrany",
                    subtitleMap: {
                        "Protipovodňová ochrana - pevné opatření": "#ff0051",
                        "Protipovodňová ochrana - mobilní stěna": "#4CE600"
                    },
                    images: ["protipovodnova-ochrana.png"],
                    visible: true,
                    display: true,
                    order: 1114
                },
                propertyStyling: {
                    valueMap: {
                        "1": "#ff0051",
                        "2": "#4CE600"
                    },
                    featureProperty: "TYP",
                    styleProperties: ["color", "fillColor"]
                }
            }
        ],
        pointLayers: [
            {
                key: "rotatingsirens",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "DRUH_SIRENY = 'rotační'",
                iconUrl: "sirenGreen.png",
                visible: true,
                disableClusterAtZoom: 8,
                dialogFn: "openDialogSiren",
                legendOptions: {
                    label: "Rotační sirény",
                    images: ["sirenGreen.png"],
                    visible: true,
                    display: true,
                    order: 1102
                }
            },
            {
                key: "electronic",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/0",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "DRUH_SIRENY = 'elektronická'",
                iconUrl: "sirenRed.png",
                visible: true,
                disableClusterAtZoom: 8,
                dialogFn: "openDialogSiren",
                legendOptions: {
                    label: "Elektronické sirény",
                    images: ["sirenRed.png"],
                    visible: true,
                    display: true,
                    order: 1103
                }
            },
            {
                key: "metrostations",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/10",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                where: "TRASA IS NOT NULL",
                visible: true,
                dialogFn: "openDialogMetroStation",
                disableClusterAtZoom: 9,
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "greenMetro.png",
                        "B": "yellowMetro.png",
                        "C": "redMetro.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - stanice",
                    images: ["greenMetro.png", "yellowMetro.png", "redMetro.png"],
                    visible: true,
                    display: true,
                    order: 1104
                }
            },
            {
                key: "metroentrances",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/8",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogMetroEntrance",
                propertyStyling: {
                    featureProperty: "TRASA",
                    valueMap: {
                        "A": "greenEnter.png",
                        "B": "yellowEnter.png",
                        "C": "redEnter.png"
                    },
                    styleProperties: ["icon"]
                },
                legendOptions: {
                    label: "Ochranný systém metra - vstupy",
                    images: ["greenEnter.png", "yellowEnter.png", "redEnter.png"],
                    visible: true,
                    display: true,
                    order: 1105
                }
            },
            {
                key: "metrofeatures",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/9",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                icon: new L.Icon({
                    iconUrl: "/styles/img/stick.png",
                    iconSize: new L.Point(2, 24),
                    iconAnchor: new L.Point(1, 12)
                }),
                legendOptions: {
                    label: "Hranice úseků ochran. syst. metra",
                    colors: ["#CCCCCC"],
                    images: ["hranice-useku.png"],
                    visible: true,
                    display: true,
                    order: 1106
                },
                visible: true,
                disableClusterAtZoom: 9
            },
            {
                key: "shelterssmall",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/3",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogShelters",
                iconUrl: "shelterSmall.png",
                legendOptions: {
                    label: "Úkryty s kapacitou menší než 150 lidí",
                    images: ["shelterSmall.png"],
                    visible: true,
                    display: true,
                    order: 1107
                }
            },
            {
                key: "sheltersbig",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/fs/ukryti_varovani_objekty_s_toxickymi_latkami/MapServer/4",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                dialogFn: "openDialogShelters",
                iconUrl: "shelterBig.png",
                legendOptions: {
                    label: "Úkryty s kapacitou 151 lidí a více",
                    images: ["shelterBig.png"],
                    visible: true,
                    display: true,
                    order: 1108
                }
            }
        ],
        btsMicrowaveStationLayer: {
            url: "",
            key: "btsmicrowavestation",
            legendOptions: {
                label: "Základnové stanice BTS",
                colors: ["#000000"],
                images: ["bts.png"],
                visible: true,
                display: true,
                order: 1109
            }
        },
        asvvMicrowaveStationLayer: {
            url: "",
            key: "asvvmicrowavestation",
            color: "#e50000",
            fillColor: "#e50000",
            stroke: "#e50000",
            legendOptions: {
                label: "Mikrovlnné spoje ASVV",
                colors: ["#e50000"],
                visible: true,
                display: true,
                order: 1110
            }
        },
        mrsMicrowaveStationLayer: {
            url: "",
            key: "mrsmicrowavestation",
            color: "#008000",
            fillColor: "#008000",
            stroke: "#008000",
            legendOptions: {
                label: "Mikrovlnné spoje MRS a Paging",
                colors: ["#008000", "#ff953f"],
                visible: true,
                display: true,
                order: 1111
            }
        },
        publicLightingLayer: {
            url: "",
            key: "publiclighting",
            legendOptions: {
                label: "Veřejné osvětlení",
                images: ["svo.png"],
                colors: ["#ffa500"],
                visible: true,
                display: true,
                order: 1115
            }
        },
        serverLayers: [
            {
                key: "cadastrez",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                isDynamic: true,
                layers: "show:12",
                queryLayers: "12",
                dialogFn: "openDialogCadastre",
                legendOptions: {
                    label: "Parcely",
                    colors: ["#000000"],
                    images: ["parcely.png"],
                    visible: true,
                    display: true,
                    order: 1117
                }
            },
            {
                key: "addresses",
                url: "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer",
                tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
                visible: true,
                isDynamic: true,
                layers: "show:7",
                queryLayers: "8",
                dialogFn: "openDialogAddresses",
                legendOptions: {
                    label: "Adresní body",
                    colors: ["#00A9E6"],
                    images: ["adresni-body.png"],
                    visible: true,
                    display: true,
                    order: 1118
                }
            }
        ],
        customLayers: [
            {
                url: "",
                key: "kamerovebody",
                //visible: true,
                legendOptions: {
                    label: "Kamerové body",
                    images: ["camera_point.png"],
                    visible: true,
                    display: true,
                    order: 1116
                }
            }
        ],
    },
    lineLayer: {
        url: "https://gs-prv.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/3",
        tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
        key: "towndistrict"
    },
    search: {
        tokenServiceUrl: "https://gs-prv.praha.eu/arcgis",
        //"MAP_SEARCH_ADDRESSES_URL": "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/8",
        "MAP_SEARCH_ADDRESSES_URL": "https://gs-prv.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/0",
        //"MAP_SEARCH_STREETS_URL": "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/6",
        "MAP_SEARCH_STREETS_URL": "https://gs-prv.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/7",
        //"MAP_SEARCH_PARCELY": "https://gs-prv.praha.eu/arcgis/rest/services/map/zakladni_mapa/MapServer/14",
        "MAP_SEARCH_PARCELY": "https://gs-prv.praha.eu/arcgis/rest/services/app_vyhledavani/vyhledavani/MapServer/1",
    }
};
window.appConfig = appConfig;
